import { useEffect } from 'react';
import { navigate } from 'gatsby';

export default function OrganizationByIdIndex({
  organizationId,
}: {
  organizationId: string;
}) {
  useEffect(() => {
    navigate(`/organizations/${organizationId}/sites`);
  });

  return <></>;
}
